<template>
  <v-dialog
    max-width="768px"
    width="100%"
    :value="true"
    scrollable
    persistent
    :fullscreen="fullscreen"
    @keydown.esc="close"
  >
    <v-card :loading="loading">
      <v-system-bar window
        >Raumreservation kopieren<v-spacer />
        <v-btn icon v-if="fullscreen" @click="fullscreen = false"
          ><v-icon>mdi-window-restore</v-icon></v-btn
        >
        <v-btn icon v-else @click="fullscreen = true"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-btn icon @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-system-bar
      >

      <v-list>
        <v-list-item>
          <v-row>
            <v-col>
              <RoomInput v-model="item.room" />
            </v-col>
            <v-col>
              <PersonInput
                label="reserviert für"
                group="employee"
                v-model="item.reservedFor"
              />
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col>
              <DateInput label="Startdatum" v-model="item.startDate" />
            </v-col>
            <v-col>
              <TimeInput label="Startzeit" v-model="item.startTime" />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="item.startTime"
                :items="periods"
                label="Lektionsbeginn"
                item-text="startTime"
                item-value="startTime"
                :loading="loadingPeriods"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <v-row>
            <v-col>
              <DateInput label="Enddatum" v-model="item.endDate" />
            </v-col>
            <v-col>
              <TimeInput label="Endzeit" v-model="item.endTime" />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="item.endTime"
                :items="periods"
                label="Lektionsende"
                item-text="endTime"
                item-value="endTime"
                :loading="loadingPeriods"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-list-item>

        <v-list-item>
          <v-row>
            <v-col cols="4">
              <v-text-field label="Beschreibung" v-model="item.description" />
            </v-col>
            <v-col cols="8">
              <v-text-field label="Kommentar" v-model="item.comment" />
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <v-card-text v-if="error">
        <v-alert border="left" outlined tile prominent type="error">{{
          error
        }}</v-alert>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          text
          @click="save"
          :disabled="!saveable"
          :loading="saving"
          color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Schliessen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DateInput from "common/components/DateInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import RoomInput from "@/components/RoomInput";
import TimeInput from "common/components/TimeInput.vue";
export default {
  name: "EventsDetails",
  components: {
    DateInput,
    PersonInput,
    RoomInput,
    TimeInput,
  },
  props: ["id", "date", "time", "room"],
  data() {
    return {
      error: null,
      loading: false,
      loadingPeriods: false,
      fullscreen: false,
      deleting: false,
      periods: [],
      saving: false,
      item: { room: { id: 0 } },
    };
  },
  watch: {
    "item.room": {
      async handler() {
        if (this.item.room.id) {
          this.fetchPeriods();
        }
      },
      deep: true,
    },
  },
  computed: {
    saveable() {
      return (
        this.item.startDate &&
        this.item.endDate &&
        this.item.startTime &&
        this.item.endTime &&
        this.item.room &&
        this.item.reservedFor
      );
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "RoomReservation", query: this.$route.query });
    },
    async fetchPeriods() {
      this.loadingPeriods = true;
      this.periods = (
        await this.apiList({
          resource: "common/period",
          query: `room=${this.item.room.id}`,
        })
      ).filter((el) => el.startTime != null);
      this.loadingPeriods = false;
    },
    async save() {
      this.saving = true;
      this.error = null;

      await this.apiPost({
        resource: "register/reservation",
        data: this.item,
        onError: (response) => (this.error = response),
      });

      setTimeout(() => {
        this.saving = false;
        if (!this.error) {
          this.$root.showSuccess("Die Reservation wurde erstellt");
          this.$emit("dataChanged");
          this.$router.push({
            name: "RoomReservation",
            query: this.$route.query,
          });
        }
      }, 1500);
    },
  },
  async created() {
    this.loading = true;

    this.item = await this.apiGet({
      resource: "register/reservation",
      id: this.id,
    });

    this.loading = false;
  },
};
</script>
